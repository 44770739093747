import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { NavbarBrand, Navbar, NavItem, Nav, Container } from "reactstrap";
// import Tooltip from "./common/tooltip";

class Header extends Component {
  state = {
    refreshing: true,
    collapseClasses: "",
    collapseOpen: false,
  };
  componentDidMount() {
    this.setState({
      refreshing: false,
    });
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  handleLogout = () => {
    localStorage.removeItem("access_token");
    this.props.history.replace("/login");
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar className="navbar-main" expand="lg" id="navbar-main">
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img alt="Computer Vision" src="logo.svg" />
              </NavbarBrand>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem className="ml-lg-2">
                  <div className="fa fa-phone navbar-phone"></div>
                  <div className="float-right ml-2">
                    <a href="tel:+917009935623" className="navbar-phone-no">
                      +91 70099 35623
                    </a>
                    <br />
                    <a href="tel:+919780879045" className="navbar-phone-no">
                      +91 97808 79045
                    </a>
                  </div>
                </NavItem>
              </Nav>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default withRouter(connect(mapStateToProps)(Header));
