import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Container, Row, Col } from "reactstrap";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <main ref="main">
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="12">
                    <h1 className="display-1 text-white text-center">404</h1>
                    <p className="lead text-white text-center">
                      Oops! Page not found
                    </p>
                  </Col>
                  <Col lg="12" className="text-center">
                    <Link to="/" className="btn btn-primary">
                      Go home
                    </Link>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
      </main>
    );
  }
}

export default NotFound;
