import React, { Component } from "react";
import { Link } from "react-router-dom";
import configData from "./../config.json";

class Maitenance extends Component {
  state = {};

  componentDidMount() {
    /*if (this.calcTime("+5.5") >= 10 && this.calcTime("+5.5") < 20) {
      this.props.history.replace("/");
    }*/
    if (process.env.REACT_APP_MAITENANCE == "false") {
      this.props.history.push("/");
    }
  }

  calcTime = (offset) => {
    let d = new Date();

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);
    return nd.getHours();
    // return nd.toLocaleString();
  };

  render() {
    return (
      <main>
        <div className="custom-navbar">
          <nav className="navbar navbar-expand-md">
            <div className="container">
              <img
                src={`${configData.PROJECT_URL}/img/prepAi-logo.svg`}
                alt="prepAi-logo"
              />
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </nav>
        </div>

        <div
          className="login-bg bg-cover height-cover item-centered"
          style={{
            backgroundImage: `url(
          ${configData.PROJECT_URL}/img/bg-gradient.png
        )`,
          }}
        >
          <div className="login--box maxw-400">
            <div className="row align-items-center no-gutters">
              <div className="col-12">
                <h4 className="maintenance--title">We are on a short break!</h4>
              </div>
            </div>
            <p className="login--support--text">
              PrepAI is temporarily offline for scheduled maintenance but soon
              it will be up. Thanks for your patience.
            </p>
            <p className="login--support--text">
              If you have any urgent requirement, reach out to us at
              <a href="mailto:prepai@datatobiz.com"> prepai@datatobiz.com </a>
              or contact via our online form at
              <a
                href="//prepai.in/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                prepai.in/contact
              </a>
              .
            </p>
            <p>&mdash; The Team</p>
            <div className="devider--shape"></div>
          </div>
        </div>
      </main>
    );
  }
}

export default Maitenance;
