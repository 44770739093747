import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./routes/Private";
import PublicRoute from "./routes/Public";

import NotFound from "./components/notFound";
import Maitenance from "./components/maitenance";

class App extends Component {
  calcTime = (offset) => {
    let d = new Date();

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);
    return nd.getHours();
    // return nd.toLocaleString();
  };

  redirectToMaitenance = () => {
    if (this.props.location.pathname != "/maitenance") {
      if (process.env.REACT_APP_MAITENANCE == "true") {
        this.props.history.push("/maitenance");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/maitenance" component={Maitenance} />
          {this.redirectToMaitenance()}
          {routes.map((route, i) => {
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoute key={i} {...route} />;
          })}

          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    refreshing: state.generalActions.refreshing,
  };
};

export default withRouter(connect(mapStateToProps)(App));
