// import lib
import { lazy } from "react";

const webRoutes = [
  {
    path: "/",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "faceDetection" */ "./../components/home")
    ),
  },
  {
    path: "/face-detection",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "faceDetection" */ "./../components/face-detection"
      )
    ),
  },
  {
    path: "/mask-detection",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "maskDetection" */ "./../components/mask-detection"
      )
    ),
  },
  {
    path: "/landing",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "landing" */ "./../components/landing")
    ),
  },
  {
    path: "/helmetviolation",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "helmetviolation" */ "./../components/helmetviolation"
      )
    ),
  },
  {
    path: "/garbage-detection",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "helmetviolation" */ "./../components/garbage-detection"
      )
    ),
  },
  {
    path: "/license-plate-detection",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "helmetviolation" */ "./../components/license-plate-detection"
      )
    ),
  },
  {
    path: "/facematching",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "helmetviolation" */ "./../components/facematching"
      )
    ),
  },
  // {
  //   path: "/clothes-virtual-tryon",
  //   exact: true,
  //   component: lazy(() =>
  //     import(
  //       /* webpackChunkName: "clothesVirtualTryon" */ "./../components/clothes-virtual-tryon"
  //     )
  //   ),
  // },
  {
    path: "/terms-conditions",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "termsConditions" */ "./../components/termsConditions"
      )
    ),
  },
];

export default webRoutes;
