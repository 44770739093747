// Actions
const SHOW_MODAL = "SHOW_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const START_LOADER = "START_LOADER";
const STOP_LOADER = "STOP_LOADER";
const PAGE_TITLE_CHANGE = "PAGE_TITLE_CHANGE";

// Action Creators
export function showModal() {
  return { type: SHOW_MODAL };
}

export function closeModal() {
  document.body.classList.remove("modal-open");
  return { type: CLOSE_MODAL };
}

export function startLoader() {
  return { type: START_LOADER };
}

export function stopLoader() {
  return { type: STOP_LOADER };
}

export function changePageTitle(title) {
  document.title = "Prep AI | " + title;
  return { type: PAGE_TITLE_CHANGE, title };
}

const initialState = {
  modelShow: false,
  refreshing: true,
  page_title: "Dashboard",
  UserName: "",
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case SHOW_MODAL:
      return modalOpen(state);
    case CLOSE_MODAL:
      return modalClose(state);
    case START_LOADER:
      return loaderStart(state);
    case STOP_LOADER:
      return loaderStop(state);
    case PAGE_TITLE_CHANGE:
      return pageTitleChange(state, payload);
    default:
      return state;
  }
}

function modalOpen(state) {
  return {
    ...state,
    modelShow: true,
  };
}

function modalClose(state) {
  return {
    ...state,
    modelShow: false,
  };
}

function loaderStart(state) {
  return {
    ...state,
    refreshing: true,
  };
}
function loaderStop(state) {
  return {
    ...state,
    refreshing: false,
  };
}

function pageTitleChange(state, payload) {
  return {
    ...state,
    page_title: payload,
  };
}
