import { combineReducers } from "redux";
import auth from "./auth";
import generalActions from "./generalActions";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  toastr: toastrReducer,
  auth,
  generalActions,
});
