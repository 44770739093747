/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="12">
                <div className="copyright">
                  © {new Date().getFullYear()} DataToBiz All Rights Reserved.
                  <a
                    href="//www.datatobiz.com/privacy-policy-for-datatobiz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="//www.datatobiz.com/disclaimer-for-datatobiz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disclaimer
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <Link target="_blank" rel="noreferrer" to="/terms-conditions">
                    Terms and Conditions
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
